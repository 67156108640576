import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISODateString } from '@mesa-labs/mesa-api/dist/types';

export type Brand = 'jll' | 'newmark' | 'cbre' | 'cushwake' | 'tishman' | 'cardinal' | 'ags' | 'unbranded';

interface UIState {
  demoMode: boolean;
  brand: Brand;
  excludeSupplementalFee: boolean;
  hasSeenGetPaidNow: boolean;
  inputMfaCodeComplete: boolean;
  showFeedbackUI: boolean;
  singleToBulkInterstitialClickTimestamps: ISODateString[];
  showEarlyPaymentOpportunitiesGlobalNotification: boolean;
  invoiceSummaryOpen: boolean;
  discoverMoreOpen: boolean;
}

const initialState: UIState = {
  demoMode: false,
  brand: 'jll',
  excludeSupplementalFee: false,
  hasSeenGetPaidNow: false,
  inputMfaCodeComplete: false,
  showFeedbackUI: true,
  singleToBulkInterstitialClickTimestamps: [],
  showEarlyPaymentOpportunitiesGlobalNotification: false,
  invoiceSummaryOpen: true,
  discoverMoreOpen: true,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    updateBrand(state: UIState, action: PayloadAction<Brand>) {
      state.brand = action.payload;
    },
    updateInputMfaCodeComplete(state: UIState, action: PayloadAction<boolean>) {
      state.inputMfaCodeComplete = action.payload;
    },
    updateHasSeenGetPaidNow(state: UIState, action: PayloadAction<boolean>) {
      state.hasSeenGetPaidNow = action.payload;
    },
    updateDemoMode(state: UIState, action: PayloadAction<boolean>) {
      state.demoMode = action.payload;
    },
    updateExcludeSupplementalFee(state: UIState, action: PayloadAction<boolean>) {
      state.excludeSupplementalFee = action.payload;
    },
    updateShowFeedbackUI(state: UIState, action: PayloadAction<boolean>) {
      state.showFeedbackUI = action.payload;
    },
    updateSingleToBulkInterstitialClickTimestamps(state: UIState, action: PayloadAction<ISODateString>) { 
      // clear this out after 20 entries to avoid too large of a record:
      if (state.singleToBulkInterstitialClickTimestamps.length === 19) {
        state.singleToBulkInterstitialClickTimestamps = [];
      } else {
        state.singleToBulkInterstitialClickTimestamps.push(action.payload);
      }
    },
    updateShowEarlyPaymentOpportunitiesGlobalNotification(state: UIState, action: PayloadAction<boolean>) { 
      state.showEarlyPaymentOpportunitiesGlobalNotification = action.payload;
    },
    updateInvoiceSummaryOpen(state: UIState, action: PayloadAction<boolean>) { 
      state.invoiceSummaryOpen = action.payload;
    },
    updateDiscoverMoreOpen(state: UIState, action: PayloadAction<boolean>) { 
      state.discoverMoreOpen = action.payload;
    }
  },
});

export const {
  updateBrand,
  updateInputMfaCodeComplete,
  updateHasSeenGetPaidNow,
  updateDemoMode,
  updateExcludeSupplementalFee,
  updateShowFeedbackUI,
  updateSingleToBulkInterstitialClickTimestamps,
  updateShowEarlyPaymentOpportunitiesGlobalNotification,
  updateInvoiceSummaryOpen,
  updateDiscoverMoreOpen,
} = uiSlice.actions;
export default uiSlice.reducer;
