import { configureStore } from '@reduxjs/toolkit';
import { Middleware } from 'redux';
import {
  createMigrate,
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as Sentry from '@sentry/react';

import rootReducer from '../slices';
import { invoicesApi } from '../api/invoices';
import { vendorApi } from '../api/vendor';
import { isRejectedAction } from '../slices/types';
import sentryReduxEnhancer, { filterActionForSentry } from '../enhancers/sentryRedux';
import { paymentsApi } from '../api/payments';
import { migrations } from './migrations';

const persistConfig = {
  key: 'root',
  version: 19,
  storage,
  whitelist: [
    'onboarding',
    'vendor',
    'invoices',
    'ui',
    'modals',
  ],
  migrate: createMigrate(migrations, { debug: false }),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const rtkQueryErrorHandler: Middleware = () => (next) => (action: any) => {
  if (isRejectedAction(action as any) && typeof action.error === 'object') {
    const errorDetails = action.error as Error;
    const error = new Error(errorDetails?.message);

    error.stack = errorDetails?.stack;
    error.name = errorDetails?.name;

    Sentry.captureException(error, {
      extra: filterActionForSentry(action) || undefined,
    });
  }

  return next(action);
};

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
    invoicesApi.middleware,
    vendorApi.middleware,
    paymentsApi.middleware,
    rtkQueryErrorHandler,
  ]),
  enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat([
    sentryReduxEnhancer,
  ]),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
