import { UUID } from '@mesa-labs/mesa-api/dist/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Brand = 'jll' | 'newmark' | 'cbre' | 'cushwake' | 'tishman' | 'unbranded';

interface UIState {
  redirectedFromInterstitialModal: boolean;
  redirectedFromInvoiceSummaryEmail: boolean;
  redirectedFromPaymentSummaryEmail: boolean;
  redirectedFromUpcomingPaymentEmail: boolean;
  showAutoGetPaidNowModal: boolean;
  showAutoGetPaidNowPaymentRequestTotal?: string;
  showAutoGetPaidNowWasRedirected?: boolean;
  showBankAccountVerificationDocumentUploadModal: boolean;
  showBankVerificationModal: boolean;
  showBulkGetPaidNowModal: boolean;
  showBulkOnDemandModal: boolean;
  showBusinessVerificationDocumentUploadModal: boolean;
  showConfirmRemoveMfaModal: boolean;
  showGetPaidNowModal: boolean;
  showInputMfaCodeModal: boolean;
  showMesaOnDemandModal: boolean;
  showRequestInFlightsModal: boolean;
  showSelectiveGetPaidNowModal: boolean;
  showSingleToBulkInterstitialModal: boolean;
  showWelcomeModal: boolean;
  showVendorSurveyModal: boolean;
  showUploadInvoiceDocumentModal: boolean;
  uploadInvoiceDocumentModalInvoiceId?: UUID;
}

const initialState: UIState = {
  redirectedFromInterstitialModal: false,
  redirectedFromInvoiceSummaryEmail: false,
  redirectedFromPaymentSummaryEmail: false,
  redirectedFromUpcomingPaymentEmail: false,
  showAutoGetPaidNowModal: false,
  showAutoGetPaidNowPaymentRequestTotal: '',
  showAutoGetPaidNowWasRedirected: false,
  showBankAccountVerificationDocumentUploadModal: false,
  showBankVerificationModal: false,
  showBulkGetPaidNowModal: false,
  showBulkOnDemandModal: false,
  showBusinessVerificationDocumentUploadModal: false,
  showConfirmRemoveMfaModal: false,
  showGetPaidNowModal: false,
  showInputMfaCodeModal: false,
  showMesaOnDemandModal: false,
  showRequestInFlightsModal: false,
  showSelectiveGetPaidNowModal: false,
  showSingleToBulkInterstitialModal: false,
  showWelcomeModal: false,
  showVendorSurveyModal: false,
  showUploadInvoiceDocumentModal: false,
  uploadInvoiceDocumentModalInvoiceId: undefined,
};

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    updateShowBankVerificationModal(state: UIState, action: PayloadAction<boolean>) {
      state.showBankVerificationModal = action.payload;
    },
    updateShowInputMfaCodeModal(state: UIState, action: PayloadAction<boolean>) {
      state.showInputMfaCodeModal = action.payload;
    },
    updateShowGetPaidNowModal(state: UIState, action: PayloadAction<{ showGetPaidNowModal: boolean, redirectedFromInterstitialModal?: boolean }>) {
      state.showGetPaidNowModal = action.payload.showGetPaidNowModal;
      state.redirectedFromInterstitialModal = action.payload.redirectedFromInterstitialModal || false;
    },
    updateShowSingleToBulkInterstitialModal(state: UIState, action: PayloadAction<boolean>) {
      state.showSingleToBulkInterstitialModal = action.payload;
    },
    updateShowBulkGetPaidNowModal(
      state: UIState, 
      action: PayloadAction<{ showBulkGetPaidNowModal: boolean, redirectedFromInterstitialModal?: boolean, redirectedFromUpcomingPaymentEmail?: boolean, redirectedFromPaymentSummaryEmail?: boolean }>,
    ) {
      state.showBulkGetPaidNowModal = action.payload.showBulkGetPaidNowModal;
      state.redirectedFromInterstitialModal = action.payload.redirectedFromInterstitialModal || false;
      state.redirectedFromInvoiceSummaryEmail = action.payload.redirectedFromUpcomingPaymentEmail || false;
      state.redirectedFromPaymentSummaryEmail = action.payload.redirectedFromPaymentSummaryEmail || false;
    },
    updateShowBulkOnDemandModal(state: UIState, action: PayloadAction<{ showBulkOnDemandModal: boolean, redirectedFromInterstitialModal?: boolean }>) {
      state.showBulkOnDemandModal = action.payload.showBulkOnDemandModal;
      state.redirectedFromInterstitialModal = action.payload.redirectedFromInterstitialModal || false;
    },
    updateShowAutoGetPaidNowModal(
      state: UIState, 
      action: PayloadAction<{ showAutoGetPaidNowModal: boolean, paymentRequestTotal?: string, wasRedirected?: boolean, redirectedFromPaymentSummaryEmail?: boolean }>,
    ) {
      state.showAutoGetPaidNowModal = action.payload.showAutoGetPaidNowModal;
      state.showAutoGetPaidNowPaymentRequestTotal = action.payload.paymentRequestTotal;
      state.showAutoGetPaidNowWasRedirected = action.payload.wasRedirected;
      state.redirectedFromPaymentSummaryEmail = action.payload.redirectedFromPaymentSummaryEmail || false;
    },
    updateShowMesaOnDemandModal(state: UIState, action: PayloadAction<{ showMesaOnDemandModal: boolean, redirectedFromInterstitialModal?: boolean}>) {
      state.showMesaOnDemandModal = action.payload.showMesaOnDemandModal;
      state.redirectedFromInterstitialModal = action.payload.redirectedFromInterstitialModal || false;
    },
    updateShowConfirmRemoveMfaModal(state: UIState, action: PayloadAction<boolean>) {
      state.showConfirmRemoveMfaModal = action.payload;
    },
    updateShowBankAccountVerificationDocumentUploadModal(state: UIState, action: PayloadAction<boolean>) {
      state.showBankAccountVerificationDocumentUploadModal = action.payload;
    },
    updateShowBusinessVerificationDocumentUploadModal(state: UIState, action: PayloadAction<boolean>) {
      state.showBusinessVerificationDocumentUploadModal = action.payload;
    },
    updateShowWelcomeModal(state: UIState, action: PayloadAction<boolean>) {
      state.showWelcomeModal = action.payload;
    },
    updateShowRequestInFlightsModal(state: UIState, action: PayloadAction<boolean>) {
      state.showRequestInFlightsModal = action.payload;
    },
    updateShowSelectiveGetPaidNowModal(state: UIState, action: PayloadAction<boolean>) {
      state.showSelectiveGetPaidNowModal = action.payload;
    },
    updateShowVendorSurveyModal(state: UIState, action: PayloadAction<boolean>) {
      state.showVendorSurveyModal = action.payload;
    },
    updateShowUploadInvoiceDocumentModal(state: UIState, action: PayloadAction<boolean>) {
      state.showUploadInvoiceDocumentModal = action.payload;
    },
    updateUploadInvoiceDocumentModalInvoiceId(state: UIState, action: PayloadAction<UUID>) {
      state.uploadInvoiceDocumentModalInvoiceId = action.payload;
    },
  },
});

export const {
  updateShowAutoGetPaidNowModal,
  updateShowBankAccountVerificationDocumentUploadModal,
  updateShowBankVerificationModal,
  updateShowBulkGetPaidNowModal,
  updateShowBulkOnDemandModal,
  updateShowBusinessVerificationDocumentUploadModal,
  updateShowConfirmRemoveMfaModal,
  updateShowGetPaidNowModal,
  updateShowInputMfaCodeModal,
  updateShowMesaOnDemandModal,
  updateShowRequestInFlightsModal,
  updateShowSelectiveGetPaidNowModal,
  updateShowSingleToBulkInterstitialModal,
  updateShowWelcomeModal,
  updateShowVendorSurveyModal,
  updateShowUploadInvoiceDocumentModal,
  updateUploadInvoiceDocumentModalInvoiceId,
} = modalsSlice.actions;
export default modalsSlice.reducer;
