import { MigrationManifest } from 'redux-persist';
import { types as api } from '@mesa-labs/mesa-api';
import { PaginationLimits } from '../slices/invoices';

export const migrations: MigrationManifest = {
  // redux-persist does not have the correct generic types
  // to allow correct typing of our state tree here with
  // PersistedState
  0: (state: any) => ({
    ...state,
    ui: {
      ...state.ui,
      activeDashboardTableTab: 'payments',
    },
  }),
  1: (state: any) => ({
    ...state,
    ui: {
      ...state.ui,
      brand: 'jll',
    },
  }),
  2: (state: any) => ({
    ...state,
    ui: {
      ...state.ui,
      showGetPaidNowModal: false,
      showInstantPayoutTooltip: true,
    },
  }),
  3: (state: any) => ({
    ...state,
    ui: {
      ...state.ui,
      autologinDemo: undefined,
    },
  }),
  4: (state: any) => ({
    ...state,
    ui: {
      ...state.ui,
      showRequestInFlightsNotification: false,
      showRequestInFlightsModal: false,
    },
  }),
  5: (state: any) => ({
    ...state,
    ui: {
      ...state.ui,
      showFeedbackUI: true,
    },
  }),
  6: (state: any) => ({
    ...state,
    ui: {
      ...state.ui,
      getPaidNowInterstitialClickTimestamps: [],
    },
  }),
  7: (state: any) => ({
    ...state,
    ui: {
      ...state.ui,
      getPaidNowInterstitialClickTimestamps: [],
    },
  }),
  8: (state: any) => ({
    ...state,
    ui: {
      ...state.ui,
      demoMode: undefined,
    },
  }),
  9: (state: any) => ({
    ...state,
    onboarding: {
      ...state.onboarding,
      instantPayoutAlwaysOn: false,
    },
  }),
  10: (state: any) => ({
    ...state,
    modals: {
      ...state.modals,
      showAutoGetPaidNowPaymentRequestTotal: '',
      showAutoGetPaidNowWasRedirected: false, 
    },
  }),
  11: (state: any) => ({
    ...state,
    onboarding: {
      ...state.onboarding,
      skipAutoInstantPayoutStep: false,
    },
  }),
  12: (state: any) => ({
    ...state,
    ui: {
      ...state.ui,
      singleToBulkInterstitialClickTimestamps: [],
    },
  }),
  13: (state: any) => ({
    ...state,
    ui: {
      ...state.ui,
      singleToBulkInterstitialClickTimestamps: [],
    },
  }),
  14: (state: any) => ({
    ...state,
    onboarding: {
      ...state.onboarding,
      promoCode: undefined,
    },
  }),
  15: (state: any) => ({
    ...state,
    ui: {
      ...state.ui,
      showEarlyPaymentOpportunitiesGlobalNotification: true,
    },
  }),
  16: (state: any) => ({
    ...state,
    invoices: {
      ...state.invoices,
      selectiveGetPaidNowRows: [],
    },
  }),
  17: (state: any) => ({
    ...state,
    invoices: {
      ...state.invoices,
      limit: {
        key: '10',
        value: 10,
      },
      invoices: [],
      page: 1,
      sortField: 'eligibleAt',
      selectiveGetPaidNowRows: [],
    },
  }),
  18: (state: any) => ({
    ...state,
    invoices: {
      ...state.invoices,
      invoices: [],
      page: 1,
      limit: PaginationLimits[0],
      total: undefined,
      searchTerm: undefined,
      startDate: undefined,
      endDate: undefined,
      sortField: 'eligibleAt',
      sortDirection: api.SortDirection.DESCENDING,
      exportFormat: api.CsvFormat.MESA_CSV_FORMAT,
      exportFormatType: 'vendor',
      excludeStates: [],
      displayState: undefined,
      instantPayoutId: undefined,
      mesaOnDemandId: undefined,
      selectiveGetPaidNowRows: [],
    },
  }),
  19: (state: any) => ({
    ...state,
    ui: {
      ...state.ui,
      invoiceSummaryOpen: true,
      discoverMoreOpen: true,
    },
  }),
  20: (state: any) => ({
    ...state,
    modals: {
      ...state.modals,
      showUploadInvoiceDocumentModal: false,
      uploadInvoiceDocumentModalInvoiceId: undefined,
    },
  }),
};